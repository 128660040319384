import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import { useGetAppById } from "framework/api/methods";
import { useEffect, useState } from "react";
import capitalizeAll from "utils/capitalizeAll";
import Anniversary from 'assets/merchandise/Occasions/Anniversary.jpeg'
import Birthday from 'assets/merchandise/Occasions/Birthday.jpeg'
import CharityEvent from 'assets/merchandise/Occasions/CharityEvent.jpeg'
import Christmas from 'assets/merchandise/Occasions/Christmas.jpeg'
import CorporateEvent from 'assets/merchandise/Occasions/CorporateEvent.jpeg'
import Festival from 'assets/merchandise/Occasions/Festival.jpeg'
import Graduation from 'assets/merchandise/Occasions/Graduation.jpeg'
import Other from 'assets/merchandise/Occasions/Other.jpeg'
import SportsEvent from 'assets/merchandise/Occasions/SportsEvent.jpeg'
import Wedding from 'assets/merchandise/Occasions/Wedding.jpeg'
import Workshop from 'assets/merchandise/Occasions/Workshop.jpeg'
import { Loader } from "components/atoms";

export const Category2Page= () => {
  const navigate = useNavigate()
 const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [categoryData, setCategoryData] = useState({
    name: '',
    subHeading: '',
    data: []
  })
  const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))
  const [isNextCategoryAvailable, setIsNextCategoryAvailable] = useState(true)
  
 const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()


 const getAppById = () => {
  getAppByIdMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
    
      const data = JSON.parse(successData?.data[0]?.category_2)
      setAppName(successData?.data[0]?.app_name)
      sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
      
      setCategoryData({
        ...categoryData,
        name: data[0]?.name,
        subHeading: '',
        data: data[0]?.data
      })
      setIsNextCategoryAvailable(successData?.data[0]?.category_3=== "[]"? false : true)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 useEffect(() => {
  if(params.id){
    getAppById()
  }
 }, [params.id])

 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])

 
 const renderImage=(name: string)=>{
  switch(name){
    case 'WORKSHOP': return Workshop ;
    case 'ANNIVERSARY': return Anniversary ;
    case 'BIRTHDAY': return Birthday;
    case 'CHARITY EVENT': return CharityEvent ;
    case 'CHRISTMAS': return Christmas ;
    case 'CORPORATE EVENT': return CorporateEvent ;
    case 'FESTIVAL': return Festival ;
    case 'GRADUATION': return Graduation ;
    case 'SPORTS EVENT': return SportsEvent ;
    case 'WEDDING': return Wedding ;
    default: return Other
  }
 }
 const token = localStorage.getItem('token')

  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
        <div className="w-full flex justify-between items-start">
            <i 
              className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
              onClick={()=>{
                  navigate(`/designer/${params.id}/product`)
              }}
            />

            <span className="text-[24px] md:hidden flex">{appName}</span>
          </div>
         

      <div className="text-[32px] leading-tight captions-medium">
         Occasion
      </div>

      <div className="text-[14px] leading-tight captions-font pt-1 font-light">
        Select the occasion
      </div>

      {getAppByIdIsLoading && <Loader  className="mt-10"/>}

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 col-span-2 md:col-span-3 lg:col-span-3 gap-x-2 gap-y-7 md:gap-y-4 mt-10 overflow-scroll hidescrl">
          {categoryData?.data?.map((item: any, index) => 
            <div className="flex flex-col justify-center items-center w-full cursor-pointer"
            key={index} 
             onClick={()=>{
              sessionStorage.setItem('category_2', item )
              if(isNextCategoryAvailable){
                navigate(`/designer/${params.id}/style`)
              }else{
                navigate(`/designer/${params.id}/designs`)
              }
              
            }}>
              <img src={renderImage(capitalizeAll(item))} alt='preview1' className='w-[120px] h-[120px] rounded-full bg-gray-200'/>
              <div className="flex pt-2 justify-center items-center w-full text-center captions-font">{capitalizeFirstLetter(item)}</div>
            </div>
          )}
      </div>
        
      </div>

    
        
    </div>
  )
  
}

export default Category2Page
