import { Button, ProgressBar, Rating } from "components/atoms"
import { useAddRatingsForApp, useGetAppById, useGetAppByIdForAuth, useGetLast4Templates } from "framework/api/methods"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import headerImage from 'assets/marketPlace/headerImage.png'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

export const AppInfo= () => {
  const navigate = useNavigate()
  const params = useParams();
  const location = useLocation()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [userRate, setUserRate] = useState(0)
  const [category, setCategory] = useState()

  const [last4templates, setLast4templates] = useState([])

  
  
  const [selectedAppData, setSelectedAppData] = useState({
    app_id: '',
    app_icon: null,
    app_name: null,
    app_description: null,
    views_count: null,
    average_rating: 0,
    rating_count: 0,
    vender_name: null,
    short_description: null,
    cover_images: [],
    total_ratings: 0,
    count_1_star: 0,
    count_2_star: 0,
    count_3_star: 0,
    count_4_star: 0,
    count_5_star: 0,
    user_rating: 0,
    category_1: null,
    app_type: null,
  })

  const [selectedAppUserRating, setSelectedAppUserRating] = useState(0)
  
 
 const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()
 const { mutate: getAppByIdAuthMutate, isLoading: getAppByIdForAuthIsLoading, } = useGetAppByIdForAuth()

 const { mutate: getLast4TemplatesMutate, isLoading: getLast4TemplatesIsLoading, } = useGetLast4Templates()

 // get app details by id
 const getAppById = () => {
  getAppByIdMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
    
      const data = successData?.data[0]
      const urls = data.cover_images.slice(1, -1).split(", ");

      const urlArray = urls.map((url: any) => url.slice(1, -1));

      setSelectedAppData({
        app_id: data.app_id,
        app_icon: data.app_icon,
        app_name: data.app_name,
        app_description: data.app_description,
        views_count: data.views_count,
        average_rating: data.average_rating,
        rating_count: data.rating_count,
        vender_name: data.vender_name,
        short_description: data.short_description,
        cover_images: urlArray,
        total_ratings: data?.total_ratings,
        count_1_star: data?.count_1_star,
        count_2_star: data?.count_2_star,
        count_3_star: data?.count_3_star,
        count_4_star: data?.count_4_star,
        count_5_star: data?.count_5_star,
        user_rating: data?.user_rating,
        category_1: data?.category_1,
        app_type: data?.app_type,
      })
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })

   getLast4TemplatesMutate({
    appId: params.id?.toString() || ''
   }, {
    onSuccess: ({ data: successData }: any) => {
      setLast4templates(successData?.data)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 
 const getAppByIdAuth = () => {
  getAppByIdAuthMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
    
      const data = successData?.data[0]
      setSelectedAppUserRating(data?.user_rating)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
  }

 useEffect(() => {
  if(params.id){
    getAppById()
  }
 }, [params.id])
 
 const token = localStorage.getItem('token')
 useEffect(() => {
  if(params.id && token){
    getAppByIdAuth()
  }
 }, [params.id, token])

 const { mutate: addRatingsForAppMutate } = useAddRatingsForApp()

 // get add ratings for app
 const addRatingsForApp = (appId: string, rate_count:string) => {
  addRatingsForAppMutate({
    appId,
    rate_count
   }, {
     onSuccess: ({ data: successData }: any) => {
      notify(successData?.message, 'success')
      getAppById()
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
     },
   })
 }

 sessionStorage.setItem('app-name','')

  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <img src={headerImage} 
          alt='header-img' 
          className='hidden md:flex w-[350px]'
        />

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
          {/* {location?.state?.isBack &&  */}
            <i 
              className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
              onClick={()=>{
                navigate('/')
              }}
            />
          {/* } */}

        <div className="flex gap-x-3 w-full justify-between">
          <div className="flex flex-col">
            <div className="headings-font text-[32px] leading-tight font-medium">{selectedAppData.app_name}</div>
            <div className="captions-font text-sm pt-1 w-full">{selectedAppData.vender_name}</div>

            <div className="captions-font text-sm pt-2 w-full md:pt-1">{selectedAppData.short_description}</div>

            <div className="md:flex gap-x-2 hidden pt-1">
              <div className="flex justify-center items-center captions-font  text-sm">{selectedAppData.average_rating?.toFixed(1)} <i className="ri-star-fill pl-1" /></div>
              <div className="flex justify-center items-center captions-font  text-sm">{selectedAppData.views_count} <i className="ri-eye-line pl-1" /></div>
            </div>

            <Button
            appearance="market-red" 
            className='hidden md:flex w-fit px-10 rounded-md md:mt-2' 
            isBlock
            disabled={selectedAppData.app_id===""?true:false}
            onClick={()=>{
              sessionStorage.setItem('app-name',selectedAppData.app_name || '')
              if(selectedAppData.app_type==='designer'){
                if(selectedAppData?.category_1  !== "[]"){
                  navigate(`/designer/${selectedAppData.app_id}/product`)
                }else{
                  navigate(`/designer/${selectedAppData.app_id}/designs`)
                }
              }
            }}
          >
            Try Now
          </Button> 
          </div>

          {selectedAppData.app_icon && 
            <img src={selectedAppData.app_icon ? selectedAppData.app_icon : undefined} 
              alt={'app-icon'} 
              className="w-[80px] h-[80px] md:w-[150px] md:h-[150px] rounded-full"
            />
          }


        </div>

        <div className="flex flex-row md:flex-col justify-between md:justify-start items-center md:items-start">
          <div className="flex gap-x-2 md:hidden">
            <div className="flex justify-center items-center captions-font  text-sm">{selectedAppData.average_rating?.toFixed(1)} <i className="ri-star-fill pl-1" /></div>
            <div className="flex justify-center items-center captions-font  text-sm">{selectedAppData.views_count} <i className="ri-eye-line pl-1" /></div>
          </div>

          <Button
            appearance="market-red" 
            className='w-fit px-10 rounded-md md:mt-2 md:hidden flex' 
            isBlock
            disabled={selectedAppData.app_id===""?true:false}
            onClick={()=>{
              sessionStorage.setItem('app-name',selectedAppData.app_name || '')
              if(selectedAppData.app_type==='designer'){
                if(selectedAppData?.category_1  !== "[]"){
                  navigate(`/designer/${selectedAppData.app_id}/product`)
                }else{
                  navigate(`/designer/${selectedAppData.app_id}/designs`)
                }
              }
            }}
          >
            Try Now
          </Button> 
        </div>
        
        <div className="captions-font text-sm pt-4 w-full">{selectedAppData.app_description}</div>
        
        <div className="flex flex-col md:flex-row-reverse h-full mt-4 lg:gap-x-24 md:gap-x-10 gap-y-2">
          <div className="flex h-full w-full md:w-1/2 gap-x-2">
            {selectedAppData?.cover_images.map((image: any, index) =>
              <img src={image ? image : undefined} 
                  alt={`cover-images-${index}`} 
                  className="w-[calc(33.3%-6px)] h-[300px] object-cover object-center"
                  key={index}
                />
            )}    
          </div>

          <div className="flex flex-col h-full w-full md:w-1/2 border-t border-t-neutral-300">
            <span className="w-full text-left pt-4 captions-medium">Ratings</span>
            <span className="w-full text-left pt-2 captions-font text-[10px] leading-none">Tell others what you think</span>

            <Rating totalStars={5} className="w-[100%] pt-3" defaultValue={selectedAppUserRating} selectedRating={userRate} onRatingChange={(rate)=>{
              setUserRate(rate)
              addRatingsForApp(selectedAppData.app_id, rate.toString())
            }}/>

            <span className="w-full text-left pt-5 captions-font text-[10px] leading-none">What others think</span>

            <div className="flex gap-x-5 mt-3">
              <div className="flex flex-col gap-y-2">
                <div className="text-[50px] leading-tight">{selectedAppData?.average_rating.toFixed(1)}</div>
                <Rating selectedRating={0} totalStars={5} className="w-[100%]" theme="red" iconClassName='text-[10px] leading-none' defaultValue={selectedAppData?.average_rating?.toFixed(1)} isEditable={false}/>
                <div className="captions- text-xs">{selectedAppData?.rating_count}</div>
              </div>

              <div className="flex flex-col w-full gap-y-2 captions-font">
                <div className="flex gap-x-2 justify-center items-center">
                  <span className="text-xs w-[20px]">5</span>
                  <ProgressBar count={selectedAppData?.count_5_star} total={selectedAppData?.rating_count} />
                </div>

                <div className="flex gap-x-2 justify-center items-center">
                  <span className="text-xs w-[20px]">4</span>
                  <ProgressBar count={selectedAppData?.count_4_star} total={selectedAppData?.rating_count} />
                </div>

                <div className="flex gap-x-2 justify-center items-center">
                  <span className="text-xs w-[20px]">3</span>
                  <ProgressBar count={selectedAppData?.count_3_star} total={selectedAppData?.rating_count} />
                </div>

                <div className="flex gap-x-2 justify-center items-center">
                  <span className="text-xs w-[20px]">2</span>
                  <ProgressBar count={selectedAppData?.count_2_star} total={selectedAppData?.rating_count} />
                </div>

                <div className="flex gap-x-2 justify-center items-center">
                  <span className="text-xs w-[20px]">1</span>
                  <ProgressBar count={selectedAppData?.count_1_star} total={selectedAppData?.rating_count} />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="h-auto w-full flex flex-col mt-10 border-t border-t-neutral-300 pt-6">
          <div className="font-bold text-white pb-6">Quick Look</div>
          <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 col-span-2 md:col-span-3 lg:col-span-4 gap-x-8 gap-y-7 md:gap-y-4">
            {last4templates?.map((item: any, index: number) => 
             <div className="flex flex-col justify-center items-center w-fulls"
              key={index} 
              >
                <img src={item.coverImage} alt='coverImage' className='w-fit h-auto rounded bg-gray-200' onClick={()=>{
                   sessionStorage.setItem('category_1', item.category_1 )
                   sessionStorage.setItem('category_2', item.category_2 )
                   sessionStorage.setItem('category_3', item.category_3 )
                   sessionStorage.setItem('category_4', item.category_4 )
                   navigate(`/designer/${selectedAppData.app_id}/designs`)
                }}/>
              </div>
            )}
          </div>
        </div>
      </div>

    
        
    </div>
  )
  
}

export default AppInfo
